<template>
  <v-data-table :headers="headers" :items="admins"
                class="px-5"
                sort-by="id" sort-desc
                :loading="loading.load"
                loading-text="Загрузка... Пожалуйста подождите"
                :mobile-breakpoint="300"
                :page="filter.page"
                :height="tableHeight"
                fixed-header
                @pagination="pagination"
                hide-default-footer
                :server-items-length="count"
                :footer-props="{itemsPerPageOptions:[25]}">

    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>

        <!-- <AdminsTableActions v-if="selected.length" class="d-none d-sm-block" /> -->

        <AdminCreateModal />

        <!-- <AdminDeleteModal /> -->
      </v-toolbar>
    </template>

    <!-- Header -->
    <!-- <template v-slot:header>
      <tr>
        <td class="border-bottom pa-4">
          <div>
            <v-checkbox :input-value="selected.length" @change="onChangeAllCheck"
                        :indeterminate="!!selected.length && selected.length !== count"
                        hide-details class="mt-auto" />
          </div>
        </td>

        <td class="border-bottom pa-3">
          <div>
            <v-text-field label="Фамилия и Имя"
                          append-icon="mdi-magnify"
                          class="font-sm"
                          :value="filter.name"
                          @input="debouncedSetFilterName"
                          dense
                          hide-details
                          outlined />
          </div>
        </td>

        <td class="border-bottom pa-3">
          <div>
            <v-text-field label="Электронная почта"
                          append-icon="mdi-magnify"
                          class="font-sm"
                          :value="filter.email"
                          @input="debouncedSetFilterEmail"
                          dense
                          hide-details
                          outlined />
          </div>
        </td>

        <td class="border-bottom pa-3">
          <div>
            <v-text-field label="Компания"
                          append-icon="mdi-magnify"
                          class="font-sm"
                          dense
                          :value="filter.company"
                          @input="debouncedSetFilterCompany"
                          hide-details
                          outlined />
          </div>
        </td>

        <td class="border-bottom pa-3">
          <div>
            <v-text-field label="Должность"
                          append-icon="mdi-magnify"
                          class="font-sm"
                          dense
                          :value="filter.position"
                          @input="debouncedSetFilterPosition"
                          hide-details
                          outlined />
          </div>
        </td>

        <td class="border-bottom pa-3">
          <v-menu
            v-model="datePickerShow"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateJoinedFilter.join(' - ')"
                label="Дата регистрации"
                append-icon="mdi-calendar"
                :prepend-inner-icon="dateJoinedFilter.length ? 'mdi-close' : null"
                @click:prepend-inner="clearJoinedDateFilter"
                class="font-sm"
                dense
                readonly
                outlined
                hide-details
                v-bind="attrs"
                v-on="on"
              />
            </template>

            <v-date-picker
              v-model="dateJoinedFilter"
              range
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="cancelJoinedDateFilter"
              >
                Отменить
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="applyJoinedDateFilter"
                :disabled="dateJoinedFilter.length !== 2"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>

        </td>

        <td class="border-bottom pa-3">
          <div class="other-width-col">
            <v-select v-model="selectedTestingStatuses"
                      :items="testingStatuses"
                      item-text="text" item-value="value"
                      label="Статус тестирования"
                      class="font-sm"
                      hide-details
                      :menu-props="{ top: false }"
                      dense multiple outlined>

              <template v-slot:prepend-item>
                <v-list-item ripple @click="testingSelectedAllToggle">
                  <v-list-item-action>
                    <v-icon :color="selectedTests.length > 0 ? 'indigo darken-4' : ''">
                      {{ iconTestingStatuses }}
                    </v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>Все</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-slot:append-item>
                <div class="text-center py-2">
                  <v-btn color="primary" small class="px-10" @click.prevent="loadAdmins">Применить</v-btn>
                </div>
              </template>
            </v-select>
          </div>
        </td>

        <td class="border-bottom pa-3"></td>

        <td class="border-bottom pa-3"></td>

        <td class="border-bottom pa-3"></td>
      </tr>
    </template> -->

    <!--  Cells  -->
    <!-- <template v-slot:item.check="{ item }">
      <div>
        <v-checkbox :input-value="selected.some((id) => id === item.id)"
                    @change="setSelected(item.id)" hide-details class="mt-auto" />
      </div>
    </template> -->

    <template v-slot:item.fullname="{ item }">
      <div class="d-flex justify-space-between align-center">
          <router-link class="text-primary mr-2" :to="{ name: 'admins-id', params: { id: item.id } }">
          {{ item.last_name }} {{ item.first_name }}
          </router-link>
        <div class="status unknown" :class="{active: item.status}" />
      </div>
    </template>

    <!-- <template v-slot:item.actions="{ item }">
      <v-icon @click="deleteAdminDialog(item)">mdi-delete</v-icon>
    </template> -->

    <template v-slot:no-data>
      Администраторов не найдено.
    </template>

    <!-- <template v-slot:footer>
      <div class="text-center py-6">
        <v-pagination v-if="paginationData"
                      :value="filter.page" @input="setFilterPage"
                      :length="paginationData.pageCount" />
      </div>
    </template> -->
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';
// import AdminsTableActions from '@/components/admins/table/AdminsTableActions';
// import CreateTestingSessionButton from '@/components/admins/table/CreateTestingSessionButton';
import AdminCreateModal from '@/components/admins/AdminCreateModal';
// import AdminDeleteModal from '@/components/admins/AdminDeleteModal';

export default {
  name: 'AdminsTable',
  components: {
    // AdminsTableActions,
    // CreateTestingSessionButton,
    AdminCreateModal,
    // AdminDeleteModal,
  },
  data: () => ({
    tableHeight: 400,
    selectedTests: [],
    headers: [
      // {
      //   text: '',
      //   value: 'check',
      //   sortable: false,
      //   width: '60px',
      // },
      {
        text: 'Фамилия и Имя',
        value: 'fullname',
        sortable: false,
        // width: '280px',
      },
      {
        text: 'Электронная почта',
        value: 'email',
        sortable: false,
        // width: '280px',
      },
      {
        text: 'Телефон',
        value: 'phone',
        sortable: false,
        // width: '280px',
      },
      {
        text: 'id',
        value: 'id',
        sortable: false,
        // width: '280px',
      },
      {
        text: 'Пользователь',
        value: 'user',
        sortable: false,
      },
      // {
      //   text: '',
      //   value: 'actions',
      //   sortable: false,
      //   align: 'end',
      // },
    ],
    paginationData: null,
  }),
  computed: {
    ...mapGetters({
      loading: 'users/admins/loading',
      admins: 'users/admins/admins',
      selected: 'users/admins/selected',
      filter: 'users/admins/filter',
      count: 'users/admins/count',
    }),

  },
  methods: {
    ...mapActions({
      setSelected: 'users/admins/setSelected',
      allSelected: 'users/admins/allSelected',
      clearSelected: 'users/admins/clearSelected',
      setFilterPage: 'users/admins/setFilterPage',
      setFilterName: 'users/admins/setFilterName',
      setFilterJoinedDatesRange: 'users/admins/setFilterJoinedDatesRange',
      setFilterEmail: 'users/admins/setFilterEmail',
      setFilterPosition: 'users/admins/setFilterPosition',
      setFilterCompany: 'users/admins/setFilterCompany',
      setFilterTestingStatus: 'users/admins/setFilterTestingStatus',
      setFilterAll: 'users/admins/setFilterAll',
      loadAdmins: 'users/admins/loadAdmins',
      deleteAdminDialog: 'users/admins/deleteAdminDialog',
    }),
    initialize() {
      this.setFilterAll(this.$route.query);
    },
    pagination(data) {
      this.paginationData = data;
    },
    onChangeAllCheck(bool) {
      if (bool) {
        this.allSelected();
      } else {
        this.clearSelected();
      }
    },
    testingSelectedAllToggle() {
      this.$nextTick(() => {
        if (this.selectedAllTestingStatuses) {
          this.selectedTestingStatuses = [];
        } else {
          this.selectedTestingStatuses = this.testingStatuses.map((s) => s.value);
        }
      });
    },
    // eslint-disable-next-line func-names
    debouncedSetFilterName: debounce(function (val) {
      this.setFilterName(val);
    }, 500),
    // eslint-disable-next-line func-names
    debouncedSetFilterEmail: debounce(function (val) {
      this.setFilterEmail(val);
    }, 500),
    // eslint-disable-next-line func-names
    debouncedSetFilterPosition: debounce(function (val) {
      this.setFilterPosition(val);
    }, 500),
    // eslint-disable-next-line func-names
    debouncedSetFilterCompany: debounce(function (val) {
      this.setFilterCompany(val);
    }, 500),

    // eslint-disable-next-line func-names

  },
  watch: {
    filter: {
      handler(val) {
        const query = {};
        for (const [key, value] of Object.entries(val)) {
          if ((value || value === 0) && !Array.isArray(value)) {
            query[key] = value;
          }
        }
        this.$router.replace({
          name: this.$route.name,
          query,
        })
          .catch(() => {});
      },
      deep: true,
    },
  },
  created() {
    this.initialize();
  },
  mounted() {
    const height = document.documentElement.clientHeight - 270;
    this.tableHeight = Math.max(height, this.tableHeight);
  },
};
</script>
<style lang="scss">
.v-list.v-sheet {
  padding: 0 !important;
}

.v-list-item__content {
  padding: 0 !important;
}

.v-list-item__action {
  margin-top: 0;
  margin-bottom: 0;
}

.v-select__selections {
  flex-wrap: nowrap;
}

.other-width-col {
  width: 13rem;
}
</style>
